<template>
    <v-container fill-height>
        <v-layout justify-center
                  wrap >
            <v-flex md12>
                <material-card :title="title">
                    <v-layout>
                        <v-flex>
                            <w-whiteboard></w-whiteboard>
                        </v-flex>
                    </v-layout>

                </material-card>
            </v-flex>

        </v-layout>

    </v-container>
</template>

<script>

    export default {
        data() {
            return {
                title:"Logistics Creator",
            }
        },
    }
</script>
